import React, { useEffect } from "react";
import AxiosInstance from "../../Config/axios";
import { useNavigate } from "react-router-dom";

const CheckoutComponent = ({ price }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const paymentStatus = sessionStorage.getItem("isPaymentSuccessful") || "";

    if (paymentStatus === "true") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const handleBuyNow = async () => {
      try {
        const response = await AxiosInstance.post(
          "/create-checkout-session",
          { price: price, product: "workshop" },

          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // Handle the response as needed
        console.log(response.data);

        // Redirect to Stripe Checkout page on success
        window.location.href = response.data.url; // Assuming the response contains the URL
      } catch (error) {
        // Handle errors
        console.error("API request failed:", error);
      }
    };
    handleBuyNow();
  }, [price]);
  return <></>;
};

export default CheckoutComponent;
