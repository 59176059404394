import { BrowserRouter, Route, Routes } from "react-router-dom";
import EnterDetails from "../Components/LandingPage/enterDetails";
import CheckoutComponent from "../Components/Payment/checkout";
import {
  checkout_path,
  enter_details_path,
  payment_cancel_path,
  payment_success_path,
  product_price,
} from "../Config/config";
import Success from "../Components/Payment/success";
import Cancel from "../Components/Payment/cancel";

const AppRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={enter_details_path}
          element={
            <>
              <EnterDetails />
            </>
          }
        />
        <Route
          path={checkout_path}
          element={
            <>
              <CheckoutComponent price={product_price} />
            </>
          }
        />
        <Route
          path={payment_success_path}
          element={
            <>
              <Success />
            </>
          }
        />
        <Route
          path={payment_cancel_path}
          element={
            <>
              <Cancel />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
