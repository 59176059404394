import React from "react";
import miscError from "../../Images/page-misc-error-light.png";
import { enter_details_path } from "../../Config/config";

const Cancel = () => {
  return (
    <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
      <div className="container-xxl container-p-y">
        <div className="misc-wrapper">
          <h2 className="mb-4 mx-2">Oops! Your Payment got Canceled</h2>
          <a href={enter_details_path} className="btn btn-primary">
            Go Back
          </a>
          <div className="mt-3">
            <img
              src={miscError}
              alt="page-misc-error-light"
              width="500"
              className="img-fluid"
              data-app-dark-img="illustrations/page-misc-error-dark.png"
              data-app-light-img="illustrations/page-misc-error-light.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cancel;
