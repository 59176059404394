import React from "react";

const NavBar = () => {
  return (
    <div className="card shadow p-3">
      <h1>Ansua Dutta</h1>
      <p>Life and Spiritual Coach</p>
    </div>
  );
};

export default NavBar;
