import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../Config/axios";

const Success: React.FC = () => {
  //eslint-disable-next-line
  const [session, setSession] = useState({});
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  //eslint-disable-next-line
  const [seconds, setSeconds] = useState(5);
  const location = useLocation();
  const sessionId = location.search.replace("?session_id=", "");
  const email: string | null = sessionStorage.getItem("email") || "";
  const first_name: string | null = sessionStorage.getItem("first_name") || "";
  const last_name: string | null = sessionStorage.getItem("last_name") || "";
  const newsletter: string | null = sessionStorage.getItem("newsletter") || "";
  const navigate = useNavigate();

  useEffect(() => {
    const paymentStatus: string | null =
      sessionStorage.getItem("isPaymentSuccessful") || "";

    if (paymentStatus === "true") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (!sessionId) {
      navigate("/");
    }
    //eslint-disable-next-line
  }, [sessionId]);

  useEffect(() => {
    async function fetchSession() {
      await AxiosInstance.get("/checkout-session?sessionId=" + sessionId).then(
        async (res) => {
          const response = await res.data;
          setSession(response);
          const status = response.payment_status;

          if (status === "paid") {
            setIsPaymentSuccessful(true);
            sendMail();
            sessionStorage.setItem("isPaymentSuccessful", "true");
          }
        }
      );
    }

    if (sessionId) {
      fetchSession();
    }

    //eslint-disable-next-line
  }, [sessionId]);
  const clearSessionStorage = () => {
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("first_name");
    sessionStorage.removeItem("last_name");
    sessionStorage.removeItem("newsletter");
  };
  const sendMail = async () => {
    const formData = new FormData();

    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("newsletter", newsletter);

    try {
      await AxiosInstance.post("/api/workshop/sendEmail", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      clearSessionStorage();
    } catch (err: any) {
      if (err.isAxiosError) {
        console.log(err.response);
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (isPaymentSuccessful) {
      const countdown = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          clearInterval(countdown);
          window.location.href =
            "https://www.ansuadutta.com/inner-guidance-workshop";
        }
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    }
  }, [seconds, navigate, isPaymentSuccessful]);
  return (
    <>
      {sessionId ? (
        <div
          className="sr-root"
          style={{
            display: "grid",
            justifyItems: "center",
            height: "100vh",
            alignContent: "center",
          }}
        >
          <div className="sr-content p-3 col-12 col-lg-8">
            <div>
              <>
                {" "}
                <h1>Thank You! </h1>
                <h3>Your Payment is Successful</h3>
                <h4>You will be receiving an email shortly</h4>
                <p>Redirecting automatically in {seconds} seconds</p> or{" "}
                <a href="https://www.ansuadutta.com/workshops">Click here</a>{" "}
              </>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Success;
