import React, { useState } from "react";
import NavBar from "../Layout/navBar";
import { checkout_path } from "../../Config/config";
import { useNavigate } from "react-router-dom";

const EnterDetails = () => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(true);
  const [isNewsLetterAgreed, setIsNewsLetterAgreed] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleSubmit = () => {
    sessionStorage.setItem("email", email);
    sessionStorage.setItem("first_name", firstName);
    sessionStorage.setItem("last_name", lastName);
    sessionStorage.setItem(
      "newsletter",
      isNewsLetterAgreed
        ? "Agreed to receive newsletter"
        : "Declined to receive newsletter"
    );
    sessionStorage.removeItem("isPaymentSuccessful");
    navigate(checkout_path);
  };

  return (
    <>
      <NavBar />
      <div
        className="container p-2 p-lg-3"
        style={{
          display: "grid",
          justifyItems: "center",
          alignContent: "center",
          height: "80vh",
        }}
      >
        <h3 className="mb-5">Enter Your Details</h3>
        <div
          className="container card shadow col-12 col-lg-6  p-3"
          data-aos="fade-left"
        >
          <div className="section-title"></div>

          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-12">
              <form className="php-email-form" onSubmit={handleSubmit}>
                <div className="d-flex">
                  <div className="col-6 form-group">
                    <input
                      style={{ height: "50px" }}
                      required
                      type="text"
                      name="first Name"
                      className="form-control"
                      id="name"
                      placeholder="First name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="col-6 form-group">
                    <input
                      style={{ height: "50px" }}
                      required
                      type="text"
                      className="form-control"
                      name="last name"
                      id="last name"
                      placeholder="Last name"
                      data-rule="last name"
                      data-msg="Please enter a valid last name"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <div className="validate"></div>
                  </div>
                </div>

                <div className="col form-group">
                  <input
                    style={{ height: "50px" }}
                    required
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                    onChange={(e) => setEmail(e.target.value)}
                  />{" "}
                </div>

                <div
                  className="consents container text-left mb-3"
                  style={{
                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                    fontSize: "12px",
                  }}
                >
                  <div className="mb-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      onChange={() => setIsTermsAgreed(!isTermsAgreed)}
                      checked={isTermsAgreed}
                    />{" "}
                    I agree to the{" "}
                    <a
                      href="https://www.ansuadutta.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and conditions
                    </a>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={isNewsLetterAgreed}
                      onChange={() =>
                        setIsNewsLetterAgreed(!isNewsLetterAgreed)
                      }
                    />{" "}
                    I agree to receive the free monthly newsletter
                  </div>
                </div>

                <div className="text-center">
                  <button
                    className="btn btn-primary  mb-3"
                    style={{ width: "92%", height: "50px" }}
                    type="submit"
                    disabled={!isTermsAgreed}
                  >
                    Check out
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterDetails;
